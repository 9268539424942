<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                src="@/assets/images/pages/foto.jpeg"
                alt="login"
                class="mx-auto img-login"
              />
              <h1 style="text-align: center; font-weight: bolder">SIRIUS</h1>
              <div class="borda1">
                <h3>Desenvolvido em parceria com:</h3>
                <div class="logos">
                  <img
                    src="@/assets/images/logo/logo_mobfeel.png"
                    width="60"
                    height="60"
                  />
                  <!-- <img
                    src="@/assets/images/logo/logo_mobfeel.png"
                    width="50"
                    height="50"
                  />
                  <img
                    src="@/assets/images/logo/logo_mobfeel.png"
                    width="50"
                    height="50"
                  />
                  <img
                    src="@/assets/images/logo/logo_mobfeel.png"
                    width="50"
                    height="50"
                  />-->
                </div>
              </div>
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 dourado">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title mb-4">
                  <!-- <h4 class="mb-4">Portal - Projeto Sirius</h4>-->
                  <p>Seja bem vindo!</p>
                  <p>Por favor, logue com sua conta.</p>
                </div>

                <div>
                  <vs-input
                    name="login"
                    icon-no-border
                    icon="icon icon-user"
                    icon-pack="feather"
                    label-placeholder="ID Ambev ou E-mail"
                    v-model="login"
                    class="w-full"
                  />
                  <span class="text-danger text-sm">{{
                    errors.first("login")
                  }}</span>

                  <vs-input
                    name="password"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    type="password"
                    label-placeholder="Senha"
                    v-model="password"
                    class="w-full mt-6"
                  />

                  <span class="text-danger text-sm">{{
                    errors.first("password")
                  }}</span>

                  <div class="flex flex-wrap justify-between my-5">
                    <vs-checkbox v-model="checkbox_remember_me" class="mb-3"
                      >Lembrar-me</vs-checkbox
                    >
                    <p>
                      <a href="#" @click="showForgotPasswordScreen"
                        >Esqueci minha senha</a
                      >
                    </p>
                  </div>
                  <vs-popup
                    title="Esqueci minha senha"
                    :active.sync="forgotPassword"
                  >
                    <p>
                      Informe seu e-mail que enviaremos instruções para resetar
                      sua senha!
                    </p>
                    <vs-input
                      name="email"
                      type="email"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="E-mail"
                      v-model="email"
                      class="w-full"
                    />
                    <vs-button class="float-right" @click="sendForgotPassword"
                      >Enviar</vs-button
                    >
                  </vs-popup>

                  <!-- Recaptcha Here -->
                  <div class="mb-6" align="center">
                    <vue-recaptcha
                      :sitekey="key"
                      @verify="verifyMethod"
                      @expired="expiredMethod"
                    >
                    </vue-recaptcha>
                  </div>

                  <vs-button
                    class="mb-6 center"
                    :disabled="!validateForm"
                    @click="doLogin"
                    >Login</vs-button
                  >
                  <div class="flex flex-wrap justify-between my-5"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { notifyError, notifySuccess } from "@/utils/helpers";
import { VueRecaptcha } from "vue-recaptcha";
import siriusAPI from "../../services";

export default {
  data() {
    return {
      login: "",
      password: "",
      checkbox_remember_me: false,
      forgotPassword: false,
      email: "",
      recaptcha: "",
      key: null,
    };
  },
  created() {
    this.key = process.env.VUE_APP_RECAPTCHA_KEY;
  },

  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.login != "" &&
        this.password != "" &&
        this.recaptcha != ""
      );
    },
  },
  methods: {
    showForgotPasswordScreen() {
      this.forgotPassword = true;
    },
    sendForgotPassword() {
      if (this.email == "") {
        notifyError(this, "Preencha com um e-mail válido!");
        return false;
      }
      this.$vs.loading();
      const data = { email: this.email };
      new siriusAPI()
        .forgotPassword(data)
        .then(() => {
          this.forgotPassword = false;
          notifySuccess(
            this,
            "E-mail para redefinição de senha enviado com sucesso!"
          );
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar enviar e-mail para redefinição de senha: " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "Login",
          text: "Você já está logado",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    doLogin() {
      this.$vs.loading();

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          login: this.login,
          password: this.password,
        },
        acl: this.$acl,
      };

      this.$store
        .dispatch("auth/loginJWT", payload)
        .then(() => {
          //this.$router.go(); force to reload page
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
          notifyError(this, "Usuário não encontrado!");
        });
    },
    verifyMethod(response) {
      this.recaptcha = response;
    },
    expiredMethod() {
      this.recaptcha = "";
    },
  },
  components: { VueRecaptcha },
};
</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
.img-login {
  height: 200px;
  background-color: #fff;
}
a {
  font-weight: bold;
  text-decoration: underline;
}
.borda1 {
  -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.23);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.23);
  width: 65%;
  margin: 0 auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
  margin-top: 35px;
  padding: 10px;
  height: 110px;
}
.borda1 h3 {
  font-weight: bold;
}
.borda1 img {
  float: left;
  display: block;
  margin-right: 10px;
}
.logos {
  width: 95%;
  margin: 0 auto;
}
.logos img {
  padding: 0.5em;
}
.dourado {
  background-color: #e7b731;
  color: black;
  font-weight: bold;
}
</style>
